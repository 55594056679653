
require('./bootstrap');

window.Vue  = require('vue');
window.Vuex = require('vuex');

import Toasted from 'vue-toasted';
import toasts from './mixins/toasts.js';

Vue.use(Toasted);

Vue.toasted.register('add_to_cart_message',(payload) => { return payload.message },
    {
        type : 'success',
        theme: "toasted-primary",
        duration: 3500,
        position: 'bottom-right',
        iconPack: "fontawesome",
        icon: 'cart-arrow-down',
        singleton: true
    })

Vue.config.devtools = true

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

// Vue.component('cart-component', require('./components/CartComponent.vue').default); remove this line

import CartComponent from './components/CartComponent';
import CartManagerComponent from './components/CartManagerComponent';
import store from './store/index';

window.cartComponent = new Vue({
    el: '#cart',
    store,
    components: {
        CartComponent
    }
});

/*
*   manage add to cart event
* */
window.cartManager = new Vue({
    el: '#cart-manager',
    store,
    components: {
        CartManagerComponent
    },
});

window.addCartItem = function addCartItem (e, productId) {
    e.preventDefault()
    window.cartManager.$children[0].addCartItem(productId)
}

if(document.getElementById('product')) new Vue({
    el: '#cart_product_pusher',
    store,
    mixins: [toasts],
    data: {
        product: {
            attributes: {
                imgSrc: ''
            },
            conditions: [],
            id: 0,
            name: '',
            price: 0,
            quantity: 1,
        }
    },
    methods: {
        addProductToCart() {
            this.$store.dispatch('addCartItem', {
                product: this.product,
            }).then( r => {
                    const productName = this.product.name
                    const productQuantity = this.product.quantity
                    this.showSuccessAddedCartItem(productName, productQuantity)
                }
            )
        }
    },
    created() {
        const el = document.getElementById('product')
        if(el) {
            this.product.id   = el.dataset.id
            this.product.name = el.dataset.name
            this.product.attributes.imgSrc = el.dataset.thumbnailPath
        }
    }
});

// Save selected tab buy a user
$('a[data-toggle="tab"]').on('click', function (e) {
    let target  = $(e.target);
    let vehicle = '';
    if( target.is('img') )
    {
        vehicle = $(this).closest('a').get(0).getAttribute('data-vehicle-slug').trim();
    }
    else
    {
        vehicle = e.target.getAttribute('data-vehicle-slug').trim();
    }
    localStorage.setItem('vehicle', vehicle);
})

//Offcanvas menu select and show vehicle related menu
$('#offcanvas-vehicle-select').change(function() {
    let vehicle = $(this).val();

    if ( vehicle != 0 ) {
        $('.offcanvas_main_menu').removeClass('d-block');
        $('.offcanvas_main_menu').addClass('d-none');
        $('#offcanvas-nav-' + vehicle).removeClass('d-none');
        $('#offcanvas-nav-' + vehicle).addClass('d-block');
        localStorage.setItem('vehicle', vehicle);
    } else {
        $('.offcanvas_main_menu').removeClass('d-block');
        $('.offcanvas_main_menu').addClass('d-none');
        localStorage.removeItem('vehicle');
    }

});

// Select previous selected vehicle value buy a user
window.onload = function() {
    let vehicle = localStorage.getItem('vehicle');

    if (vehicle) {
        $('a[data-vehicle-slug="'+vehicle+'"]').trigger('click');

        $('#offcanvas-vehicle-select').val(vehicle);
        $('#offcanvas-nav-' + vehicle).removeClass('d-none');
        $('#offcanvas-nav-' + vehicle).addClass('d-block');
    }
};
