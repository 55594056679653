<template>

</template>

<script>

    import toasts from '../mixins/toasts.js';

    export default {
        name: "cart-manager-component",
        mixins: [toasts],
        methods: {
            addCartItem: function(productId, quantity = 1) {
                this.$store.dispatch('addCartItem', {
                    product: {
                        id: productId,
                        quantity: quantity
                    }
                }).then( r => {
                        const product = this.$store.getters.getCartItemById(productId)
                        this.showSuccessAddedCartItem(product.name, quantity)
                    }
                );
            }
        }
    }
</script>

<style scoped>

</style>
