import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store ({
    strict: true, // TODO: delete this line in production
    state: {
        cartItems: [],
        cartState: false
    },
    mutations: {
        UPDATE_PRODUCTS (state, payload) {
            this.state.cartItems = payload.cartItems;
        },
        SET_CART_LOAD_STATE(state, payload) {
            state.cartState = payload
        }
    },
    actions: {
        getCartItems( {commit, state}) {
            axios.get('/api/cart/items')
                .then((response) => {
                    commit('UPDATE_PRODUCTS', { cartItems: response.data.products } );
                    commit('SET_CART_LOAD_STATE', { cartState: true } );
                })
        },
        addCartItem( {commit}, cartItem ) {
            axios.post('/api/cart/item', cartItem)
                .then((response) => {
                    commit('UPDATE_PRODUCTS', { cartItems: response.data.products } );
                })
        },
        deleteCartItem( {commit, state}, index ) {
            axios.delete('/api/cart/item', {
                data: {
                    product: state.cartItems[index]
                }
            })
                .then((response) => {
                    commit('UPDATE_PRODUCTS', { cartItems: response.data.products } );
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    },
    getters: {
        cartTotal: state => {
            return state.cartItems.reduce((acc, cartItem) => {
                return (cartItem.quantity * cartItem.price) + acc;
            }, 0).toFixed(2);
        },
        getCartItemById: state => ( itemId ) => {
            return state.cartItems.find( i => i.id === itemId)
        }
    }
})
